.img-partner {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.hidden-partner {
    opacity: 0;
    filter: blur(5px);
    transition: all 1s;
}


.show-partner {
    filter: blur(0);
    opacity: 1;
}