p {
  font-family: 'Montserrat', sans-serif;
}

a {
  font-family: 'Montserrat', sans-serif;
}

.text-wrap-balance {
  text-wrap: pretty;
}