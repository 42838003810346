.container-page {
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.container-page section {
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
  height: 100vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container-page::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container-page {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#section-content {
  background-image: url(../../assets/images/bg-section3.jpg);
  color: #fefae0;
  background-size: cover;
}

#section-map {
  background-image: url(../../assets/images/bg-section2.jpg);
  color: #fefae0;
  background-size: cover;
}

#section-contact {
  background-image: url(../../assets/images/bg-section3.jpg);
  color: #fefae0;
  background-size: cover;
}

.main-content {
  display: flex;
  height: 100vh;
  width: 100%;
  color: white;
  background-image: url(../../assets/images/bg1.jpg);
  background-size: cover;
}

.container {
  background-color: rgba(0, 0, 0, 0.6);
  max-width: none !important;
}

.text-title-principal {
  letter-spacing: 0.6em;
  font-size: 2em;
  font-weight: 500;
  text-shadow: 3px 2px 45px black;
}

.img-julian {
  width: 50%;
}



.p-subtitle {
  text-shadow: 3px 2px 45px black;
  font-size: 0.7rem;
}

.dot {
  height: 1.5vh;
  width: 1.5vh;
  margin-bottom: 10px;
  background-color: #bbb;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dot:hover {
  cursor: pointer;
  background-color: rgb(117, 117, 117);
  transform: scale3d(1.5, 1.5, 1.5);
}

.dot-active {
  background-color: #c4661f;
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3);
}

.dot-container {
  position: fixed;
  right: 1.5%;
  top: 45%;
  z-index: 1000;
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}


.show {
  filter: blur(0);
  transform: translateX(0);
  opacity: 1;
}

.contact-2 {
  transition-delay: 200ms;
}

.contact-3 {
  transition-delay: 400ms;
}

.contact-4 {
  transition-delay: 600ms;
}

.img-content {
  max-height: 15vw;
  min-width: 100px;
  min-height: 100px;
  transform: scale(0.9);
  transition: all 0.2s ease-in-out;
}

.img-content:hover {
  transform: scale(1);
  cursor: pointer;
}

.item-content:hover {
  cursor: pointer;
}


/* Small devices (landscape phones, 576px and up)
Medium devices (tablets, 768px and up)
Large devices (desktops, 992px and up)
X-Large devices (large desktops, 1200px and up)
XX-Large devices (larger desktops, 1400px and up) */

@media (min-width: 576px) {
  .p-subtitle {
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) {
  .text-title-principal {
    font-size: 4em;
  }

  .img-julian {
    width: 75%;
  }

  .p-subtitle {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .text-title-principal {
    font-size: 6em;
  }
}