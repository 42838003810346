.hidden-title {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}


.show-title {
    filter: blur(0);
    transform: translateX(0);
    opacity: 1;
}