/* h1 {
    font-family: "Cormorant Garamond", serif;
} */

.bg-title {
    background-image: url(../../assets/images/bg2-wide.jpg);
    background-size: cover;
}

.container-news {
    padding-top: 6rem !important;
}